/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
/*    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* global styles - applied by default*/
.z-card-image {
  border-radius: 30px;
  width: 220px;
  height: 320px;
  box-shadow: 0px 0px 3px 1px #ccc;
  border: 2px solid #B0C4DE;
  margin: 10px 10px 10px 10px;
  background-repeat: no-repeat;
  background-position: center center;
}
/* styles for browsers smaller than 414px pixel & pixel XL, iPhone 6/7/8 plus*/
@media (max-width: 414px) {
  .z-card-image{
    max-width: 100%;
    display: block;
    width: 235px;
    height: 345px;
    padding: auto;
    margin: 10px 10px 10px 10px;
    border: 2px solid #B0C4DE;
    box-shadow: 0px 0px 3px 1px #ccc;
 } 
}
/* styles for browsers smaller than 375px - iPhone X*/
@media (max-width: 375px) {
  .z-card-image{
    max-width: 100%;
    display: block;
    height: 295px;
    width: 200px;
    border: 2px solid #B0C4DE;
    box-shadow: 0px 0px 3px 1px #ccc;
 } 
}

/* styles for browsers smaller than 360px - galaxy S5 & Moto G4 */
@media (max-width: 360px) {
  .z-card-image{
    max-width: 100%;
    display: block;
    height: 280px;
    width: 190px;
    border: 2px solid #B0C4DE;
    box-shadow: 0px 0px 3px 1px #ccc;
 } 
}

/* styles for browsers smaller than 320px - iPhone/SE */
@media (max-width: 320px) {
  .z-card-image{
    max-width: 100%;
    display: block;
    height: 220px;
    width: 150px;
    border: 2px solid #B0C4DE;
    box-shadow: 0px 0px 3px 1px #ccc;
 } 
}

.view-text{
  font-weight: bold;
  text-shadow: 1px 1px 1px #ccc;
}

.z-card {
  margin: 20px;
}

.z-card-info {
  margin-top: 10px;
  min-height: 100px;
}

.z-card-title {
  font-size: 25px;
  margin: 0px;
}

.z-card-sub-title {
  font-size: 12px;
  margin: 0px;
}

.z-success-msg {
  color:green;
  padding: 10px 15px;
}

.z-err-msg {
  color: red;
  padding: 10px 15px;
}


/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 20px;
  top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: black;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 36px;
  width: 36px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #d7d3cb;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #003333;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #323741;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.no-match-link {
  color: #4db8ff;
  font-size: 1.1rem;
  line-height: 0;
  margin-top: -70px;
  font-weight: 700;
  text-decoration: underline;
}


/* styling for FAQ */

.faqs {
  width: 100%;
  max-width: 768px; /* ipad size, once we go beyond this size, we will then center it*/
  margin: 0 auto;
  padding: 25px;
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  background-color: #ffff;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
  border: 4px solid darkcyan;

}

.faqs .faq .faq-question {
  
  position: relative;
  font-size: 20px;
  padding-right: 80px;
  font-weight: 700;
  transition: all 0.6ease;
}

/* <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>*/
.faqs .faq .faq-question::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30%;
  height: 30px;

  background-image: url('../src//assets//logo/vector/down-chevron.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  transition: all 0.7s ease-out;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.7s ease;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  transform: translateY(-50%) rotate(180deg);
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;  
}
